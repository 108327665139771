import styled from 'styled-components';
import i18n from 'src/utils/translate';
import ButtonDropdown from 'src/components/buttons/buttonDropdown';
import FollowButton from 'src/components/buttons/followButton/followButton';
import { useDispatch, useSelector } from 'src/store/store';
import { useIsCommunityMember, userHasRole } from 'src/utils/context';
import Button from 'src/components/buttons/button';
import { showModal } from 'src/modules/shared/modal/actions';
import { ModalType } from 'src/constants/modalTypes';
import { Role } from 'src/constants/types';
import { useMediaQuery } from 'src/utils/mediaQuery';
export default function AdminActions() {
  const dispatch = useDispatch();
  const currentCommunity = useSelector(state => state.context.currentCommunity);
  const currentUser = useSelector(state => state.context.currentUser);
  const isCommunityMember = useIsCommunityMember();
  const isHUStaff = userHasRole(currentUser, [Role.HealthUnlocked]);
  const isMobile = useMediaQuery('underScreenSmall');

  // As we're not using DropdownItem (from reactstrap) in this dropdown, we need
  // to add tabindex & role manually for the 2 buttons to be navigable by keyboard
  return <StyledDropDown isRightAligned={isMobile} text={isMobile && isCommunityMember ? i18n.t('Joined') : i18n.t('More')} data-sentry-element="StyledDropDown" data-sentry-component="AdminActions" data-sentry-source-file="adminActions.tsx">
      {isHUStaff && <Button className="dropdown-item" role="menuitem" tabIndex={0} type="none" onClick={() => dispatch(showModal({
      modalType: ModalType.CommunityInvites
    }))}>
          {i18n.t('Invite')}
        </Button>}
      <FollowButton buttonType="none" className="dropdown-item" community={currentCommunity} followType="community" hasIcon={false} isFollowing={isCommunityMember} role="menuitem" tabIndex={0} unFollowText={isMobile && isCommunityMember ? i18n.t('Leave community') : undefined} data-sentry-element="FollowButton" data-sentry-source-file="adminActions.tsx" />
    </StyledDropDown>;
}
const StyledDropDown = styled(ButtonDropdown)`
  .dropdown-item {
    text-align: left;
    border: 0;
    border-radius: 0;
    &:hover, &:focus, &:active {
      color: ${({
  theme
}) => theme.colorTextBlack};
    }
  }
`;