import { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
import { capitalize } from 'underscore.string';
import { useSelector } from 'src/store/store';
import config from 'config/config';
import i18n from 'src/utils/translate';
import { Section } from 'src/constants/footfall';
import { footfall } from 'src/components/siteBar/sitebarFootfall';
import { Avatar, ImageContainer, ImageIcon, LinkText, TopSiteBar, StyledLink } from 'src/components/siteBar/sharedStyles';
import { textLinkSecondary } from 'styles-js/mixins/typography';
import Button from 'src/components/buttons/button';
import GlobalSearch from 'src/modules/search/shared/components/searchBar';
export default function MobileSiteBarTop() {
  const {
    pathname
  } = useRouter();
  const currentUser = useSelector(({
    context
  }) => context.currentUser);
  const isOwnProfile = useSelector(({
    pages
  }) => pages.profile?.profileUser?.isOwnProfile);
  const [isSearching, setIsSearching] = useState(false);
  return <TopContainer className="js-sitebar" data-sentry-element="TopContainer" data-sentry-component="MobileSiteBarTop" data-sentry-source-file="mobileSiteBarTop.tsx">
      {!isSearching && <Link legacyBehavior passHref href="/newsfeed">
          <StyledLink $isActive={'/newsfeed' === pathname} onClick={() => footfall(Section.HuLogo)}>
            <ImageContainer>
              <ImageIcon alt="Home" src={`${config.cdn.logos}H-logo-speech-bubble-green.svg`} />
            </ImageContainer>
            <LinkText $isActive={'/newsfeed' === pathname}>{capitalize(i18n.t('home'))}</LinkText>
          </StyledLink>
        </Link>}

      <StyledGlobalSearch isSmall={true} placeholder={i18n.t('Search HealthUnlocked')} onClick={() => setIsSearching(true)} onSearch={() => setIsSearching(false)} data-sentry-element="StyledGlobalSearch" data-sentry-source-file="mobileSiteBarTop.tsx" />

      {isSearching && <PlainButton text={i18n.t('Cancel')} type="none" onClick={() => setIsSearching(false)} />}

      {!isSearching && <Link legacyBehavior passHref as={`/user/${currentUser?.username}`} href={`/user/profile?username=${currentUser?.username}`}>
          <StyledLink $isActive={'/user' === pathname && isOwnProfile} onClick={() => footfall(Section.More)}>
            <ImageContainer>
              <Avatar alt="Your avatar" src={currentUser?.avatarImageUrl} />
            </ImageContainer>
            <LinkText $isActive={'/user' === pathname && isOwnProfile}>{capitalize(i18n.t('profile'))}</LinkText>
          </StyledLink>
        </Link>}

    </TopContainer>;
}
const TopContainer = styled(TopSiteBar)`
  display: flex;
  align-items: center;
`;
const StyledGlobalSearch = styled(GlobalSearch)`
  margin: 0 5px;
`;
const PlainButton = styled(Button)`
  ${textLinkSecondary}
`;