import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { capitalize } from 'underscore.string';
import Image from 'next/image';
import { useDispatch, useSelector } from 'src/store/store';
import config from 'config/config';
import i18n from 'src/utils/translate';
import { footfall } from 'src/components/siteBar/sitebarFootfall';
import { Section } from 'src/constants/footfall';
import { fetchNotifications } from 'src/modules/shared/context/actions';
import HubMenu from 'src/components/siteBar/hubMenu';
import NotificationMenu from 'src/components/siteBar/notificationMenu';
import DesktopMoreMenu from 'src/components/siteBar/desktopMoreMenu';
import { Avatar, Counter, ImageContainer, ImageIcon, LinkText, TopSiteBar, StyledLink } from 'src/components/siteBar/sharedStyles';
import PaperPlane from 'src/components/siteBar/icons/paperPlane';
import Pen from 'src/components/siteBar/icons/pen';
import GlobalSearch from 'src/modules/search/shared/components/searchBar';
export default function DesktopSiteBar() {
  const {
    pathname
  } = useRouter();
  const dispatch = useDispatch();
  const unreadMessagesCount = useSelector(({
    context
  }) => context.unreadMessagesCount);
  const currentUser = useSelector(({
    context
  }) => context.currentUser);
  const isOwnProfile = useSelector(({
    pages
  }) => pages.profile?.profileUser?.isOwnProfile);
  const menuRef = useRef<HTMLDivElement>(null);
  const [openHubMenu, setOpenHubMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const closeAllMenus = () => {
    setOpenHubMenu(false);
    setOpenNotifications(false);
    setOpenMoreMenu(false);
  };
  const handleClickOutside = (event: MouseEvent): void => {
    if (!menuRef?.current?.contains((event.target as HTMLElement))) closeAllMenus();
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []); // eslint-disable-line

  return <TopSiteBar data-testid="desktop-sitebar" ref={menuRef} data-sentry-element="TopSiteBar" data-sentry-component="DesktopSiteBar" data-sentry-source-file="desktopSiteBar.tsx">
      <Inner data-sentry-element="Inner" data-sentry-source-file="desktopSiteBar.tsx">
        <LeftSide data-sentry-element="LeftSide" data-sentry-source-file="desktopSiteBar.tsx">
          <Link legacyBehavior passHref href="/newsfeed" data-sentry-element="Link" data-sentry-source-file="desktopSiteBar.tsx">
            <A $isActive={pathname.includes('newsfeed')} data-testid="sitebar-newsfeed" onClick={() => footfall(Section.Home)} data-sentry-element="A" data-sentry-source-file="desktopSiteBar.tsx">
              <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="desktopSiteBar.tsx">
                <ImageIcon alt="Home" src={`${config.cdn.logos}H-logo-speech-bubble-green.svg`} data-sentry-element="ImageIcon" data-sentry-source-file="desktopSiteBar.tsx" />
              </ImageContainer>
              <LinkText $isActive={pathname.includes('newsfeed')} data-sentry-element="LinkText" data-sentry-source-file="desktopSiteBar.tsx">{capitalize(i18n.t('home'))}</LinkText>
            </A>
          </Link>

          <StyledSearchBar isSmall={true} placeholder={i18n.t('Search HealthUnlocked')} onClick={() => footfall(Section.Search)} data-sentry-element="StyledSearchBar" data-sentry-source-file="desktopSiteBar.tsx" />
        </LeftSide>

        <Link legacyBehavior passHref href="/newsfeed" data-sentry-element="Link" data-sentry-source-file="desktopSiteBar.tsx">
          <HULogoLink data-testid="sitebar-hu-logo" onClick={() => footfall(Section.HuLogo)} data-sentry-element="HULogoLink" data-sentry-source-file="desktopSiteBar.tsx">
            <Image alt="HealthUnlocked logo" height="18" src={`${config.cdn.logos}healthunlocked.svg`} width="188" data-sentry-element="Image" data-sentry-source-file="desktopSiteBar.tsx" />
          </HULogoLink>
        </Link>

        <RightSide data-sentry-element="RightSide" data-sentry-source-file="desktopSiteBar.tsx">
          <Link legacyBehavior passHref as={`/user/${currentUser?.username}`} href={`/user/profile?username=${currentUser?.username}`} data-sentry-element="Link" data-sentry-source-file="desktopSiteBar.tsx">
            <A $isActive={pathname.includes('user') && isOwnProfile} data-testid="sitebar-profile" onClick={() => footfall(Section.More)} data-sentry-element="A" data-sentry-source-file="desktopSiteBar.tsx">
              <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="desktopSiteBar.tsx">
                <Avatar alt="Your avatar" src={currentUser?.avatarImageUrl} data-sentry-element="Avatar" data-sentry-source-file="desktopSiteBar.tsx" />
              </ImageContainer>
              <LinkText $isActive={pathname.includes('user') && isOwnProfile} data-sentry-element="LinkText" data-sentry-source-file="desktopSiteBar.tsx">{capitalize(i18n.t('profile'))}</LinkText>
            </A>
          </Link>

          <HubMenu handleClick={() => {
          footfall(Section.MyHub);
          closeAllMenus();
          setOpenHubMenu(!openHubMenu);
        }} isOpen={openHubMenu} setOpenHubMenu={setOpenHubMenu} data-sentry-element="HubMenu" data-sentry-source-file="desktopSiteBar.tsx" />

          <Link legacyBehavior passHref as="/messages" href="/messages" data-sentry-element="Link" data-sentry-source-file="desktopSiteBar.tsx">
            <A $isActive={pathname.includes('messages')} data-testid="sitebar-chat" onClick={() => footfall(Section.Chat)} data-sentry-element="A" data-sentry-source-file="desktopSiteBar.tsx">
              <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="desktopSiteBar.tsx">
                <PaperPlane data-sentry-element="PaperPlane" data-sentry-source-file="desktopSiteBar.tsx" />
                {unreadMessagesCount > 0 && <Counter data-testid="chat-message-number">{unreadMessagesCount}</Counter>}
              </ImageContainer>
              <LinkText $isActive={pathname.includes('messages')} data-sentry-element="LinkText" data-sentry-source-file="desktopSiteBar.tsx">{capitalize(i18n.t('chat'))}</LinkText>
            </A>
          </Link>

          <Link legacyBehavior passHref as="/write" href="/community/write" data-sentry-element="Link" data-sentry-source-file="desktopSiteBar.tsx">
            <A $isActive={pathname.includes('write')} data-testid="sitebar-write" onClick={() => footfall(Section.Post)} data-sentry-element="A" data-sentry-source-file="desktopSiteBar.tsx">
              <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="desktopSiteBar.tsx">
                <Pen data-sentry-element="Pen" data-sentry-source-file="desktopSiteBar.tsx" />
              </ImageContainer>
              <LinkText $isActive={pathname.includes('write')} data-sentry-element="LinkText" data-sentry-source-file="desktopSiteBar.tsx">{capitalize(i18n.t('post'))}</LinkText>
            </A>
          </Link>

          <NotificationMenu handleClick={() => {
          footfall(Section.Alerts);
          closeAllMenus();
          setOpenNotifications(!openNotifications);
          dispatch(fetchNotifications());
        }} isOpen={openNotifications} setOpenNotifications={setOpenNotifications} data-sentry-element="NotificationMenu" data-sentry-source-file="desktopSiteBar.tsx" />

          <DesktopMoreMenu handleClick={() => {
          footfall(Section.More);
          closeAllMenus();
          setOpenMoreMenu(!openMoreMenu);
        }} isOpen={openMoreMenu} setOpenMoreMenu={setOpenMoreMenu} data-sentry-element="DesktopMoreMenu" data-sentry-source-file="desktopSiteBar.tsx" />
        </RightSide>
      </Inner>
    </TopSiteBar>;
}
const Inner = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;
const StyledSearchBar = styled(GlobalSearch)`
  width: 300px;
  margin-left: 5px;
`;
const RightSide = styled.div`
  display: flex;
`;
const A = styled(StyledLink)`
  ${RightSide} & {
    width: 60px;
  }
`;
const HULogoLink = styled.a`
  display: flex;
  align-items: center;
  @media (${({
  theme
}) => theme.underScreenMedium}) {
    display: none;
  }
`;