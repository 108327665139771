import styled from 'styled-components';
export default function Separator({
  className
}: {
  className?: string;
}) {
  return <Dot className={className} data-sentry-element="Dot" data-sentry-component="Separator" data-sentry-source-file="separator.tsx">•</Dot>;
}
const Dot = styled.span`
  color: ${({
  theme
}) => theme.colorGreyLight};
`;