import styled from 'styled-components';
import { useRouter } from 'next/router';
import FollowButton from 'src/components/buttons/followButton/followButton';
import WriteButton from 'src/modules/community/shared/components/writeButton';
import { Element, Section } from 'src/constants/footfall';
import { hideWriteCTA } from 'src/constants/restrictedCommunities';
import AdminActions from 'src/modules/community/shared/components/header/adminActions';
import { Role } from 'src/constants/types';
import { useIsCommunityMember, userHasRole } from 'src/utils/context';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import GlobalSearch from 'src/modules/search/shared/components/searchBar';
import { useMediaQuery } from 'src/utils/mediaQuery';
export default function CommunityActions({
  currentPage
}: {
  currentPage: string;
}) {
  const router = useRouter();
  const currentCommunity = useSelector(({
    context
  }) => context.currentCommunity);
  const currentUser = useSelector(({
    context
  }) => context.currentUser);
  const isCommunityMember = useIsCommunityMember();
  const isHUStaff = userHasRole(currentUser, [Role.HealthUnlocked]);
  const isArchivedCommunityAndHUStaff = currentCommunity?.isArchived && isHUStaff;
  const isMobile = useMediaQuery('underScreenSmall');
  const isMemberSearch = router.query.searchType === 'members' || router.pathname === '/[communitySlug]/members';
  const searchType = isMemberSearch ? 'members' : 'posts';
  if (!isArchivedCommunityAndHUStaff && currentCommunity?.isArchived) return null;
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="CommunityActions" data-sentry-source-file="communityActions.tsx">
      {isMobile && <GlobalSearch defaultValue={(router.query.query as string)} disableAutosuggest={isMemberSearch} isCommunitySearch={true} isSmall={true} placeholder={isMobile ? i18n.t('Search') : i18n.t('Search community')} searchType={searchType} />}
      {isHUStaff || isMobile && isCommunityMember ? <AdminActions /> : <FollowButton buttonType={isMobile ? 'primary' : 'secondary'} clickMetadata={{
      element: Element.Follow,
      section: ('community-' + currentPage as Section)
    }} community={currentCommunity} followType="community" isFollowing={isCommunityMember} />}
      {isMobile ? null : currentCommunity?.slug && !hideWriteCTA.includes(currentCommunity?.slug) && <WriteButton text={i18n.t('Write')} trackingSection={('community-' + currentPage as Section)} />}
    </Wrapper>;
}
const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  @media (${({
  theme
}) => theme.overScreenSmall}) {
    margin-left: auto;
  }
`;