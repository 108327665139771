// @ts-strict-ignore
import { Editor, Range, Transforms, Point, Location, BaseRange } from 'slate';
import { isHotkey } from 'is-hotkey';
import { ReactEditor } from 'slate-react';
import { KeyboardEvent, MutableRefObject, useMemo, useRef } from 'react';
import { MAX_MENTIONS, NAV_KEYS } from 'src/constants/write';
import { VALID_MENTION, VALID_MENTION_TEXT } from 'src/constants/regex';
import { CustomElement, ImageElement } from 'src/components/textEditor/customTypes';
const ZERO_WIDTH_NO_BREAK = /[\uFEFF]+/;

// to call these functions, import MyEditor, and call MyEditor.functionName(args)
export const MyEditor = {
  ...Editor,
  // issue discovered on android mobiles only - all text has a preceding zero-width non-break bom item
  // use this function to get full string of editor contents -> MyEditor.getString()
  getString(editor: Editor, at: Location, options?: {
    voids?: boolean;
  }): string {
    return Editor.string(editor, at, options).replace(ZERO_WIDTH_NO_BREAK, '');
  },
  // clear editor
  reset(editor: Editor): void {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, [])
      }
    });
  },
  focus(editor: Editor): void {
    ReactEditor.focus(editor);
    editor.onChange();
  },
  countElementOccurrence(editor: Editor, elementType: string): number {
    const count = editor.children.reduce((counter, block) => {
      // @ts-expect-error block doesn't like children
      const blockElements = block.children.filter(({
        type
      }) => type === elementType).length;
      counter += blockElements;
      return counter;
    }, 0);
    return count;
  },
  isMarkActive(editor: Editor, format: string): boolean {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  },
  toggleMarkToolbar(editor: Editor, format: string, isActive: boolean): void {
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  },
  addSoftBreak(event: KeyboardEvent, editor: Editor): void {
    if (isHotkey('shift+enter', event)) {
      event.preventDefault();
      Transforms.insertText(editor, '\n', {
        voids: true
      });
    }
  },
  imposeTextLimit(editor: Editor, event: KeyboardEvent, maxChars?: number) {
    // will ignore mentions as a part of text
    if (maxChars && MyEditor.getString(editor, []).length >= maxChars && !NAV_KEYS.concat('Backspace').includes(event.key) && !(event.metaKey && event.key === 'a') && !(event.ctrlKey && event.key === 'a')) {
      event.preventDefault();
      return false;
    }
  },
  preventKeys(keys: [key: string], event: KeyboardEvent): void {
    // eg, ['enter', 'shift+enter']
    if (isHotkey(keys, event)) {
      event.preventDefault();
    }
  },
  removeImage(editor: Editor): void {
    Transforms.removeNodes(editor, {
      at: [0]
    }); // image at the beginning
  },
  insertImage({
    editor,
    url = null,
    file = null
  }: {
    editor: Editor;
    url?: string | ArrayBuffer;
    file?: File;
  }): void {
    const imageUrl = file && URL.createObjectURL(file);
    const image: ImageElement = {
      type: 'image',
      url: (imageUrl as string) || (url as string),
      file,
      children: [{
        text: ''
      }]
    };

    // delete previous image if one present, assuming image is always at the beginning
    const block = (editor.children[0] as CustomElement);
    if (block.type === 'image') {
      MyEditor.removeImage(editor);
    }

    // at specifies location - here we always add image at the beginning
    Transforms.insertNodes(editor, image, {
      at: [0]
    });
  },
  canAddMention(editor: Editor, max?: number): boolean {
    const mentionsLimit = max || MAX_MENTIONS;
    const mentionCount = MyEditor.countElementOccurrence(editor, 'mention');
    return mentionsLimit > mentionCount;
  },
  captureMention(editor: Editor, setTarget: (str: BaseRange) => void, setSearchTerm: (str: string) => void, setIndex: (idx: number) => void): void {
    const {
      selection
    } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      if (!start) return;

      // we need to be able to start with a special character: - or _
      const previousCharLoc = Editor.before(editor, start, {
        unit: 'character'
      });
      const previousChar = previousCharLoc && MyEditor.getString(editor, {
        anchor: previousCharLoc,
        focus: start
      });
      const isPreviousCharValid = previousChar && previousChar.match(VALID_MENTION_TEXT);
      let wholeWordRange, wholeWordMatch, afterAndBeforeMatch;
      if (isPreviousCharValid) {
        const previous = Editor.before(editor, previousCharLoc);
        wholeWordRange = previous && wholeWord(editor);
        const wholeWordText = wholeWordRange && MyEditor.getString(editor, wholeWordRange);
        wholeWordMatch = wholeWordText && wholeWordText.match(VALID_MENTION);
      }
      if (wholeWordMatch) {
        // quit mentions if the next character is invalid
        const after = Editor.after(editor, start);
        const afterRange = Editor.range(editor, start, after);
        const afterText = MyEditor.getString(editor, afterRange);
        const afterMatch = afterText.match(/^(\s|$)/);

        // before check for empty space so that mentions don't appear when typing emails
        const before = Editor.before(editor, wholeWordRange.anchor, {
          unit: 'character'
        });
        let beforeText = before && Editor.string(editor, {
          anchor: before,
          focus: wholeWordRange.anchor
        }); // must use Editor.string - want ZERO_WIDTH char
        beforeText = beforeText && beforeText.replace(ZERO_WIDTH_NO_BREAK, ' ') || ' '; // replace with empty space, so next line, can activate regex (android only fix)
        const beforeMatch = beforeText === ' ' || wholeWordRange.anchor.offset === 0;
        afterAndBeforeMatch = afterMatch && beforeMatch;
      }
      if (wholeWordMatch && afterAndBeforeMatch) {
        setTarget(wholeWordRange);
        setSearchTerm(wholeWordMatch[1]);
        setIndex(0);
        return;
      }
    }
    setTarget(null);
  }
};

// slate unit 'word' does not accept special chars, but our mentions can have _ and -
function wholeWord(editor: Editor): Range | undefined {
  const selRange: [Point, Point] = Range.edges(editor.selection);
  const end = selRange[1];
  let start = selRange[0];
  let currStart: Point = start;
  function traverse(): boolean {
    const before = Editor.before(editor, currStart, {
      unit: 'character'
    });
    const charBefore = before && MyEditor.getString(editor, {
      anchor: before,
      focus: currStart
    });
    if (before && charBefore && charBefore.match(VALID_MENTION_TEXT)) {
      currStart = before;
      if (currStart.offset === 0) {
        return false;
      } // don't move if you're at the start
      return true;
    } else {
      return false;
    }
  }
  currStart = start;
  while (traverse()); // go through the previous chars to get the full word
  start = currStart;

  // return the previous char based on which we decide if it is a mention, i.e., get @
  return {
    anchor: Editor.before(editor, start, {
      unit: 'character'
    }) ?? start,
    focus: end
  };
}
export function useEditor(editor: Editor, ref?: MutableRefObject<Editor | null>) {
  const editorInstance = useMemo(() => editor, []); // eslint-disable-line react-hooks/exhaustive-deps
  const newRef = useRef(editorInstance);
  const editorRef = ref ?? newRef;
  if (!editorRef.current) editorRef.current = editorInstance;
  return (editorRef.current as Editor); // this code is necessary because of hot-code reload, which in *dev* throws can't find child error
}