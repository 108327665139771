// @ts-strict-ignore
/* eslint-disable max-lines */
import router from 'next/router';
import { captureException } from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { ReduxState } from 'src/store/store';
import i18n from 'src/utils/translate';
import { pluralize } from 'src/utils/string';
import { del, getPublic, post } from 'src/utils/api';
import { ModalType } from 'src/constants/modalTypes';
import actionTypes from 'src/modules/community/shared/actionTypes';
import { closeModal, setModalProps, showModal } from 'src/modules/shared/modal/actions';
import { setAlert } from 'src/components/alert/actions';
import { fetchCurrentUser, fetchPermissions } from 'src/modules/shared/context/actions';
import { fetchPost, fetchPrivatePost } from 'src/modules/community/singlePost/actions/fetchActions';
import InformationCard from 'src/components/informationCard';
import Spacer from 'src/components/spacer';
import { ModalProps } from 'src/modules/shared/modal/components/defaultModal';
import paginationResultsPerPage from 'src/constants/paginationResultsPerPage';
export function followCommunity({
  communitySlug,
  successMessage
}: {
  communitySlug?: string;
  successMessage: string | JSX.Element;
}) {
  return async (dispatch, getState: () => ReduxState) => {
    try {
      const {
        context: {
          currentCommunity,
          currentUser
        },
        pages: {
          singlePost
        }
      } = getState();
      const slug = communitySlug ? communitySlug : currentCommunity.slug;
      await post(`private/communities/${slug}/follow/${currentUser.userId}`);
      dispatch({
        type: actionTypes.FOLLOW_COMMUNITY_SUCCESS
      });
      dispatch(setAlert(successMessage, 'success'));

      // Update followed communities, permissions and post if on single post page
      await dispatch(fetchCurrentUser());
      if (currentCommunity?.communityId) {
        await dispatch(fetchPermissions(currentCommunity.communityId));
        if (singlePost?.id) {
          dispatch(fetchPrivatePost(singlePost.id, currentCommunity.slug));
        }
      }
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert(ex?.message));
      dispatch({
        type: actionTypes.FOLLOW_COMMUNITY_FAILURE
      });
    }
  };
}
export function showUnfollowModal({
  communitySlug,
  communityName
}: {
  communitySlug: string;
  communityName: string;
}) {
  return dispatch => {
    const modalProps: ModalProps = {
      acceptButtonText: i18n.t('Leave Community'),
      cancelButtonText: i18n.t('Cancel'),
      children: i18n.t('Leaving the community will affect your news feed, emails, notifications and badges. Your contributions will remain on the community unless you delete them.'),
      title: i18n.t('Are you sure you want to do this?'),
      onAccept: () => {
        dispatch(unfollowCommunity({
          communitySlug,
          successMessage: i18n.t('You have left %1$s').replace('%1$s', communityName) + '!'
        }));
        dispatch(closeModal());
      }
    };
    dispatch(showModal({
      modalType: ModalType.Default,
      modalProps
    }));
  };
}
function unfollowCommunity({
  communitySlug,
  successMessage
}: {
  communitySlug: string;
  successMessage: string;
}) {
  return async (dispatch, getState: () => ReduxState) => {
    try {
      const {
        context: {
          currentUser,
          currentCommunity
        },
        pages: {
          singlePost
        }
      } = getState();
      await del(`private/communities/${communitySlug}/follow/${currentUser.userId}`);
      dispatch({
        type: actionTypes.UNFOLLOW_COMMUNITY_SUCCESS,
        communitySlug
      });
      dispatch(setAlert(successMessage, 'success'));

      // Update followed communities, permissions and post if on single post page
      await dispatch(fetchCurrentUser());
      if (currentCommunity?.communityId) {
        await dispatch(fetchPermissions(currentCommunity.communityId));
        if (singlePost?.id) {
          dispatch(fetchPost(singlePost.id, currentCommunity.slug));
        }
      }
    } catch (ex) {
      captureException(ex);
      dispatch({
        type: actionTypes.UNFOLLOW_COMMUNITY_FAILURE
      });
      dispatch(setAlert(ex.message));
    }
  };
}
export const sendInvites = (communitySlug, emails) => async dispatch => {
  try {
    const {
      data: {
        invitesSent: successful,
        failedValidation: invalid
      }
    } = await post(`private/communities/${communitySlug}/invites`, {
      emails
    });
    if (successful.length > 0) {
      dispatch(setAlert(pluralize(i18n.t('%1$s invite sent!'), i18n.t('%1$s invites sent!'), successful.length), 'success'));
    }
    if (successful.length === emails.length) {
      dispatch(closeModal());
    } else {
      dispatch(setModalProps({
        successful,
        invalid
      }));
    }
  } catch (ex) {
    captureException(ex);
    dispatch(setAlert(ex.message));
  }
};
export const fetchCommunityTopics = (communitySlug: string) => {
  return async dispatch => {
    try {
      const {
        data
      } = await getPublic(`topics/${communitySlug}`);
      const topics = data.map(topic => ({
        id: topic.topicId,
        name: topic.name,
        postCount: topic.postCount
      }));
      dispatch({
        type: actionTypes.FETCH_COMMUNITY_TOPICS_SUCCESS,
        topics
      });
    } catch (ex) {
      dispatch({
        type: actionTypes.FETCH_COMMUNITY_TOPICS_FAILURE,
        error: ex
      });
      dispatch(setAlert(ex?.message));
    }
  };
};
export function showRestrictMemberModal(memberId: number, memberUsername: string, communitySlug: string) {
  return dispatch => {
    const modalProps: ModalProps = {
      acceptButtonText: i18n.t('Restrict'),
      cancelButtonText: i18n.t('Cancel'),
      children: <>
          <p>{i18n.t('Restricted members cannot create or respond to posts, or send private messages to members of this community.')}</p>
          <Spacer />
          <InformationCard type="warning">
            {i18n.t('Please note that there may be a slight delay before this change is fully reflected on the community.')}
          </InformationCard>
        </>,
      title: i18n.t('Restrict member'),
      onAccept: () => dispatch(restrictUser(memberId, memberUsername, communitySlug))
    };
    dispatch(showModal({
      modalType: ModalType.Default,
      modalProps
    }));
  };
}
export function showUnrestrictMemberModal(memberId: number, memberUsername: string, communitySlug: string) {
  return dispatch => {
    dispatch(showModal({
      modalType: ModalType.Default,
      modalProps: {
        acceptButtonText: i18n.t('Unrestrict'),
        cancelButtonText: i18n.t('Cancel'),
        children: <>
            <p>{i18n.t('Restricted members cannot create or respond to posts, or send private messages to members of this community.')}</p>
            <Spacer />
            <InformationCard type="warning">
              {i18n.t('Please note that there may be a slight delay before this change is fully reflected on the community.')}
            </InformationCard>
          </>,
        title: i18n.t('Unrestrict member') + ' ' + memberUsername,
        onAccept: () => dispatch(unrestrictUser(memberId, memberUsername, communitySlug))
      }
    }));
  };
}
export const restrictUser = (memberId: number, memberUsername: string, communitySlug: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({
      type: actionTypes.RESTRICT_USER
    });
    try {
      await post(`private/members/${communitySlug}/restrict/${memberId}`);
      const successMessage = i18n.t('Member %1$s has been restricted').replace('%1$s', memberUsername);
      dispatch(setAlert(successMessage, 'success'));

      // Restricting can happen on 4 pages (members pages, members search results page, SPP, poll page)
      // For members pages and members search results page, we reload the page on success
      const isMembersSearchPage = router.pathname === '/community/communitySearch';
      const isMembersPage = router.pathname === '/[communitySlug]/members';
      if (isMembersPage) {
        // Pages router
        const {
          members,
          total
        } = getState().pages.communityMembers.data;
        handleLastPageOnMembersPage(members, total);
      } else if (isMembersSearchPage) {
        // custom router
        const {
          membersResults,
          totalResults
        } = getState().pages.search;
        handleLastPageOnMembersSearchPage(membersResults, totalResults, communitySlug);
      }
    } catch (ex: unknown) {
      captureException(ex);
      let message = undefined;
      if (ex instanceof AxiosError && ex?.response?.status === 403) {
        message = i18n.t('You do not have permission to perform this action.');
      }
      dispatch(setAlert(message));
    }
  };
};
export const unrestrictUser = (memberId: number, memberUsername: string, communitySlug: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({
      type: actionTypes.UNRESTRICT_USER
    });
    try {
      await del(`private/members/${communitySlug}/restrict/${memberId}`);
      const successMessage = i18n.t('Member %1$s has been unrestricted').replace('%1$s', memberUsername);
      dispatch(setAlert(successMessage, 'success'));

      // Unrestricting can happen on 3 pages (restricted members page, SPP, poll page)
      // For restricted members page, we reload the page on success
      const isMembersPage = router.pathname === '/[communitySlug]/members';
      if (isMembersPage) {
        const {
          members,
          total
        } = getState().pages.communityMembers.data;
        handleLastPageOnMembersPage(members, total);
      }
    } catch (ex: unknown) {
      captureException(ex);
      let message = undefined;
      if (ex instanceof AxiosError && ex?.response?.status === 403) {
        message = i18n.t('You do not have permission to perform this action.');
      }
      dispatch(setAlert(message));
    }
  };
};
function handleLastPageOnMembersPage(membersData, totalResults) {
  const page = Number(router.query?.page) || 1;
  const lastPage = Math.ceil(totalResults / paginationResultsPerPage.searchMembers);
  const isLastPage = page === lastPage;
  setTimeout(() => {
    // When on the last page with only 1 result, go back to the first page
    if (isLastPage && membersData.length === 1) {
      const newSearchParams = new URLSearchParams(location.search.substring(1));
      newSearchParams.delete('page');
      const paramsString = newSearchParams.size > 0 ? `?${newSearchParams.toString()}` : '';
      router.push(`${location.pathname}${paramsString}`);
    } else {
      router.reload();
    }
  }, 2000);
}
function handleLastPageOnMembersSearchPage(membersData, totalResults, communitySlug) {
  const page = Number(router.query?.page) || 1;
  const lastPage = Math.ceil(totalResults / paginationResultsPerPage.searchMembers);
  const isLastPage = page === lastPage;
  setTimeout(() => {
    // When on the last page with only 1 result, go back to the first page
    if (isLastPage && membersData.length === 1) {
      router.push({
        pathname: '/community/communitySearch',
        query: {
          communitySlug,
          searchType: 'members',
          query: router.query.query
        }
      }, {
        pathname: `/${communitySlug}/search/members`,
        query: {
          query: router.query.query
        }
      });
    } else {
      router.reload();
    }
  }, 2000);
}