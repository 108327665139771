import styled, { css } from 'styled-components';
import { titleSmall } from 'styles-js/mixins/typography';
import { LinkButton } from 'styles-js/buttons';

export const LinkText = styled.span<{ $isActive: boolean }>`
  ${titleSmall};
  font-size: 10px;
  line-height: 19px;
  font-weight: ${({ theme }) => theme.fontWeightBoldTitle};
  color: ${({ theme, $isActive }) => $isActive ? theme.colorBlack : theme.colorGreyDark};
`;

const LinkStyles = css`
  display: flex;
  width: 54px;
  height: 100%;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colorGreyDark};

  &:hover, &:focus, &:active {
    background-color: ${({ theme }) => theme.colorGreenLighter};
    color: ${({ theme }) => theme.colorBlack};
    ${LinkText} {
      color: ${({ theme }) => theme.colorBlack};
    }
  }
`;

export const StyledLink = styled.a<{ $isActive: boolean }>`
  ${LinkStyles};
  &:hover, &:focus, &:active {
    background-color: ${({ theme }) => theme.colorBlueBackground};
    color: ${({ theme }) => theme.colorGreyDark};
    ${LinkText} {
      color: ${({ theme }) => theme.colorGreyDark};
    }
  }
  ${({ $isActive }) => $isActive && css`
    background-color: ${({ theme }) => theme.colorBlueBackground};
  `};
`;

const SiteBar = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1049;
  background-color: ${({ theme }) => theme.colorWhite};
`;
export const TopSiteBar = styled(SiteBar)`
  top: 0;
  border-bottom: ${({ theme }) => theme.greyLine};
  height: ${({ theme }) => theme.navHeightTop};
`;
export const BottomSiteBar = styled(SiteBar)`
  bottom: 0;
  border-top: ${({ theme }) => theme.greyLine};
  height: calc(${({ theme }) => theme.navHeightTop} + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  ${StyledLink} {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
`;

export const ImageIcon = styled.img`
  width: 22px;
  height: 22px;
`;

export const Avatar = styled(ImageIcon)`
  border-radius: 50%;
`;

export const Counter = styled.div`
  position: absolute;
  top: 12px;
  right: 38%;
  min-width: 20px;
  height: 20px;
  margin-right: -20px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colorYellow};
  color: ${({ theme }) => theme.colorBlack};
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
`;

export const DropdownButton = styled(LinkButton)<{ $isActive: boolean }>`
  ${LinkStyles};
  width: 60px;
  border-radius: 0;
  transition: none;
  ${({ $isActive }) => $isActive && css`
    background-color: ${({ theme }) => theme.colorGreenLighter};
  `};
`;
