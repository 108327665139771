// @ts-strict-ignore
import styled from 'styled-components';
import { PostType, Notification } from 'src/constants/types';
import Avatar, { Size } from 'src/components/avatar';
import PostLink from 'src/modules/shared/links/postLink';
import i18n from 'src/utils/translate';
import UserLink from 'src/modules/shared/links/userLink';
import { HIDDEN_USERNAME } from 'src/constants/hiddenUser';
export default function NotificationItem({
  notification: {
    type,
    post,
    user
  }
}: {
  notification: Notification;
}) {
  const hiddenOrUsername = user.isDeleted ? i18n.t(HIDDEN_USERNAME) : user.username;
  switch (type) {
    case 'post-follows':
      return <PostLink communitySlug={post.community.slug} isPrivate={false} postId={post.id} postTypeId={post.postTypeId} urlEncodedTitle={post.urlEncodedTitle}>
        <Avatar hash={user.profileImageHash} name={hiddenOrUsername} size={Size.Small} />
        <Bold>{hiddenOrUsername}</Bold> {i18n.t('saved your post')} <Bold>{post.title}</Bold>
      </PostLink>;
    case 'user-follows':
      return <UserLink user={user}>
        <Avatar hash={user.profileImageHash} name={hiddenOrUsername} size={Size.Small} />
        <Bold>{hiddenOrUsername}</Bold> {i18n.t('is now following you!')}
      </UserLink>;
    case 'post-rate':
      {
        switch (post.postTypeId) {
          case PostType.Post:
            return <PostLink communitySlug={post.community.slug} isPrivate={false} postId={post.id} postTypeId={post.postTypeId} urlEncodedTitle={post.urlEncodedTitle}>
            <Avatar hash={user.profileImageHash} name={hiddenOrUsername} size={Size.Small} />
            <Bold>{hiddenOrUsername}</Bold> {i18n.t('liked your post')} <Bold>{post.snippet}</Bold>
          </PostLink>;
          case PostType.Reply:
            return <PostLink communitySlug={post.community.slug} isPrivate={false} postId={post.superParent.id} postTypeId={post.superParent.postTypeId} responseId={post.id} urlEncodedTitle={post.superParent.urlEncodedTitle}>
            <Avatar hash={user.profileImageHash} name={hiddenOrUsername} size={Size.Small} />
            <Bold>{hiddenOrUsername}</Bold> {i18n.t('liked your reply')} <Bold>{post.snippet}</Bold>
          </PostLink>;
        }
        break;
      }
    case 'user-mention':
      {
        switch (post.postTypeId) {
          case PostType.Post:
            return <PostLink communitySlug={post.community.slug} isPrivate={false} postId={post.id} postTypeId={post.postTypeId} urlEncodedTitle={post.urlEncodedTitle}>
            <Avatar hash={user.profileImageHash} name={hiddenOrUsername} size={Size.Small} />
            <Bold>{hiddenOrUsername}</Bold> {i18n.t('has mentioned you in')} <Bold>{post.title}</Bold>
          </PostLink>;
          case PostType.Reply:
            return <PostLink communitySlug={post.community.slug} isPrivate={false} postId={post.superParent.id} postTypeId={post.superParent.postTypeId} responseId={post.id} urlEncodedTitle={post.superParent.urlEncodedTitle}>
            <Avatar hash={user.profileImageHash} name={hiddenOrUsername} size={Size.Small} />
            <Bold>{hiddenOrUsername}</Bold> {i18n.t('has mentioned you in')} <Bold>{post.superParent.title}</Bold>
          </PostLink>;
        }
        break;
      }
    case 'items':
      {
        switch (post.postTypeId) {
          case PostType.Reply:
            return <PostLink communitySlug={post.community.slug} isPrivate={false} postId={post.superParent.id} postTypeId={post.superParent.postTypeId} responseId={post.id} urlEncodedTitle={post.superParent.urlEncodedTitle}>
            <Avatar hash={user.profileImageHash} name={hiddenOrUsername} size={Size.Small} />
            <Bold>{hiddenOrUsername}</Bold> {i18n.t('responded to')} <Bold>{post.superParent.title}</Bold>
          </PostLink>;
          case PostType.Poll:
            return <PostLink communitySlug={post.community.slug} isPrivate={false} postId={post.id} postTypeId={post.postTypeId} urlEncodedTitle={post.urlEncodedTitle}>
            <Avatar hash={user.profileImageHash} name={hiddenOrUsername} size={Size.Small} />
            <Bold>{hiddenOrUsername}</Bold> {i18n.t('set a poll')} <Bold>{post.title}</Bold>
          </PostLink>;
          case PostType.Post:
          default:
            return <PostLink communitySlug={post.community.slug} isPrivate={false} postId={post.id} postTypeId={post.postTypeId} urlEncodedTitle={post.urlEncodedTitle}>
            <Avatar hash={user.profileImageHash} name={hiddenOrUsername} size={Size.Small} />
            <Bold>{hiddenOrUsername}</Bold> {i18n.t('wrote')} <Bold>{post.title}</Bold>
          </PostLink>;
        }
      }
    default:
      return null;
  }
}
const Bold = styled.span`
  font-weight: ${({
  theme
}) => theme.fontWeightBold};
`;