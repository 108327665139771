import styled, { CSSProp, DefaultTheme, css } from 'styled-components';
export const BackgroundImage = styled.div<{
  $customCss: CSSProp<DefaultTheme>;
  $backgroundImage: string;
  $isGrabbing: boolean;
  $isPreview: boolean;
  $top: number;
}>`
  ${({
  $customCss
}) => $customCss};
  background-image: ${({
  $backgroundImage
}) => `url(${$backgroundImage})`};
  background-size: cover;
  background-position: ${({
  $top
}) => `0px ${$top}px`};
  background-repeat: no-repeat;
  position: relative;
  ${({
  $isGrabbing,
  $isPreview
}) => $isPreview && css`
    cursor: move;
    cursor: ${$isGrabbing ? 'grabbing' : 'grab'};
  `};
`;
export const ButtonsContainer = styled.div<{
  $isEditProfile: boolean;
  $type: 'avatar' | 'banner';
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  pointer-events: none;
  button {
    pointer-events: all;
  }
`;
export const UploadButtonContainer = styled(ButtonsContainer)`
  justify-content: center;
  align-items: center;
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    ${({
  $type
}) => $type === 'banner' && css`
      justify-content: flex-end;
      align-items: flex-start;
      margin-right: 5px;
    `}
  }
  button {
    margin-right: 0;
  }
  // Hide upload buttons when on community page and not hovering/focusing and not previewing
  ${({
  $isEditProfile
}) => !$isEditProfile && css`
    button {
      display: none;
    }
    ${BackgroundImage}:hover & button, #css-focusable:focus & button {
      display: block;
    }
  `}
`;
export const PreviewButtons = styled(ButtonsContainer)`
  ${({
  $type
}) => $type === 'avatar' && css`
    justify-content: center;
    align-items: flex-end;
    bottom: -40px;
  `}
  ${({
  $type
}) => $type === 'banner' && css`
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px;
  `}
`;
export const profileBanner = css`
  height: 200px;
  @media (${({
  theme
}) => theme.overScreenSmall}) {
    border-radius: ${({
  theme
}) => theme.cardBorderRadius};
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; 
  }
`;
export const profileAvatar = css`
  border-radius: 50%;
  height: 130px;
  width: 130px;
  border: 5px solid ${({
  theme
}) => theme.colorWhite};
  box-shadow: ${({
  theme
}) => theme.shadowLight};
`;