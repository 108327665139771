import styled from 'styled-components';
import CommunityBanner from 'src/modules/community/shared/components/header/communityBanner';
import Logo from 'src/modules/community/shared/components/header/logo';
import CommunityActions from 'src/modules/community/shared/components/header/communityActions';
import Details from 'src/modules/community/shared/components/header/details';
export default function CommunityHeader({
  currentPage = ''
}: {
  currentPage?: string;
}) {
  return <Wrapper className="js-community-header" data-sentry-element="Wrapper" data-sentry-component="CommunityHeader" data-sentry-source-file="communityHeader.tsx">
      <CommunityBanner data-sentry-element="CommunityBanner" data-sentry-source-file="communityHeader.tsx" />
      <Content data-testid="community-header-desc" data-sentry-element="Content" data-sentry-source-file="communityHeader.tsx">
        <Logo data-sentry-element="Logo" data-sentry-source-file="communityHeader.tsx" />
        <Details data-sentry-element="Details" data-sentry-source-file="communityHeader.tsx" />
        <CommunityActions currentPage={currentPage} data-sentry-element="CommunityActions" data-sentry-source-file="communityHeader.tsx" />
      </Content>
    </Wrapper>;
}
const Wrapper = styled.div`
  background-color: ${({
  theme
}) => theme.colorWhite};
  width: 100%;
  border-bottom: ${({
  theme
}) => theme.greyLine};
`;
const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px 15px 0;
  max-width: 1200px;
  margin: 0 auto;
  
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    flex-direction: column;
    padding: 15px;
  }
  
  @media (${({
  theme
}) => theme.underScreenXlarge}) and (${({
  theme
}) => theme.overScreenSmall}) {
    margin-left: 20px;
  }
`;