import styled, { css } from 'styled-components';
export const FadeIn = styled.div<{
  $isVisible: boolean;
}>`
  display: none;
  ${({
  $isVisible
}) => $isVisible && css`
    display: block;
    animation: fade-in 0.5s ease;
    @keyframes fade-in {
      from { opacity: 0; }
      to { opacity: 1; }
    }
  `}
`;