import { useState } from 'react';
import { useSelector } from 'src/store/store';
import { performSearch } from 'src/utils/router';
import { getPublic } from 'src/utils/api';
import { SearchBarForm } from 'src/modules/search/shared/components/searchBarForm';
import i18n from 'src/utils/translate';
type Props = {
  className?: string;
  dataTestId?: string;
  defaultValue?: string;
  disableAutosuggest?: boolean;
  isAutoFocus?: boolean;
  isCommunitySearch?: boolean;
  isSmall?: boolean;
  onClick?: () => void;
  onSearch?: (value?: string) => void;
  placeholder?: string;
  searchType?: 'posts' | 'members' | 'communities';
};
export default function GlobalSearch({
  className,
  dataTestId = 'search-bar',
  defaultValue,
  disableAutosuggest,
  isAutoFocus,
  isCommunitySearch,
  isSmall,
  onClick,
  onSearch,
  placeholder = i18n.t('Search HealthUnlocked'),
  searchType = 'posts'
}: Props) {
  const currentCommunity = useSelector(({
    context
  }) => context.currentCommunity);
  const communityId = isCommunitySearch ? currentCommunity?.communityId : 'all';
  const communitySlug = isCommunitySearch ? currentCommunity?.slug : null;
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const onSubmit = (value: string) => {
    performSearch(value, String(communityId), communitySlug, searchType);
    onSearch?.(value);
  };
  const onSuggestionsFetchRequested = async ({
    value = ''
  }) => {
    if (value.length <= 1 || disableAutosuggest) return;
    try {
      const {
        data
      } = (await getPublic(`search/autocomplete?q=${value}`) as {
        data: string[];
      });
      setSuggestions(data.slice(0, 10).map(suggestion => suggestion.toLowerCase()));
    } catch (_) {
      setSuggestions([]);
    }
  };
  return <SearchBarForm className={className} dataTestId={dataTestId} defaultValue={defaultValue} isAutoFocus={isAutoFocus && !defaultValue} isSmall={isSmall} placeholder={placeholder} setSuggestions={setSuggestions} suggestions={suggestions} onClick={onClick} onSubmit={onSubmit} onSuggestionsFetchRequested={onSuggestionsFetchRequested} data-sentry-element="SearchBarForm" data-sentry-component="GlobalSearch" data-sentry-source-file="searchBar.tsx" />;
}