import { useEffect, useState, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';
import { useDispatch, useSelector } from 'src/store/store';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Element, Section } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
import { getLocalStorageItem, Key, setLocalStorageItem } from 'src/utils/localStorage';
import { useIsLoggedIn } from 'src/utils/hooks/useIsLoggedIn';
import Button from 'src/components/buttons/button';
import { text, textLight, textSmall, titleMedium, titleSmall } from 'styles-js/mixins/typography';
import { hideFloatingSignupBox } from 'src/modules/shared/context/actions';
import { FadeIn } from 'src/components/animate';
export default function FloatingSignupBox() {
  const dispatch = useDispatch();
  const isFloatingSignupBoxVisible = useSelector(({
    context
  }) => context.isFloatingSignupBoxVisible);
  const isLoggedIn = useIsLoggedIn();
  const [email, setEmail] = useState('');
  const {
    isBoxDismissed,
    setIsBoxDismissed
  } = useIsBoxDismissed();
  const isBoxVisible = useShowBoxOnScroll(isBoxDismissed);
  if (isLoggedIn || isBoxDismissed || !isFloatingSignupBoxVisible || !isBoxVisible) return null;
  const hide = () => {
    setIsBoxDismissed(true);
    setLocalStorageItem(Key.hideFloatingBox, 'true');
    dispatch(hideFloatingSignupBox());
  };
  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    trackFootfallEvent(Event.Clicked, {
      clickMetadata: {
        section: Section.StickyFooter,
        element: Element.Join
      },
      clickType: 'sign-up'
    });
    dispatch(showSignupModal({
      defaultEmail: email
    }));
  };
  return <Container $isVisible={isBoxVisible} data-sentry-element="Container" data-sentry-component="FloatingSignupBox" data-sentry-source-file="floatingSignupBox.tsx">
      <Inner data-sentry-element="Inner" data-sentry-source-file="floatingSignupBox.tsx">
        <CloseButton icon="close" type="none" onClick={hide} data-sentry-element="CloseButton" data-sentry-source-file="floatingSignupBox.tsx" />
        <Title data-sentry-element="Title" data-sentry-source-file="floatingSignupBox.tsx">
          <span className="hidden-xs">
            {i18n.t('Connect with others who understand.')}
          </span>
          <span className="visible-xs">
            {i18n.t('Join the #1 peer-support network for health. Ask questions. Get answers.')}
          </span>
        </Title>
        <Content data-sentry-element="Content" data-sentry-source-file="floatingSignupBox.tsx">
          <span className="hidden-xs">
            {i18n.t('Join the #1 peer-support network for health. Ask questions. Get answers.')}
          </span>
          <Form onSubmit={onSubmit} data-sentry-element="Form" data-sentry-source-file="floatingSignupBox.tsx">
            <EmailInput placeholder={i18n.t('email@address.com')} type="text" value={email} onChange={e => setEmail(e.target.value)} data-sentry-element="EmailInput" data-sentry-source-file="floatingSignupBox.tsx" />
            <JoinButton isSubmit={true} size="lg" data-sentry-element="JoinButton" data-sentry-source-file="floatingSignupBox.tsx">
              <span className="hidden-xs">{i18n.t('Join HealthUnlocked')}</span>
              <span className="visible-xs">{i18n.t('Join')}</span>
            </JoinButton>
          </Form>
        </Content>
      </Inner>
    </Container>;
}
function useShowBoxOnScroll(isBoxDismissed: boolean) {
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const onScroll = throttle(() => setIsBoxVisible(window.scrollY > 0), 300);
  useEffect(() => {
    if (isBoxDismissed || isBoxVisible) return;
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isBoxDismissed, isBoxVisible, onScroll]);
  return isBoxVisible;
}
function useIsBoxDismissed() {
  const [isBoxDismissed, setIsBoxDismissed] = useState(true);
  useEffect(() => {
    setIsBoxDismissed(getLocalStorageItem(Key.hideFloatingBox) === 'true');
  }, []);
  return {
    isBoxDismissed,
    setIsBoxDismissed
  };
}
const Container = styled(FadeIn)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({
  theme
}) => theme.colorBlueDarker};
  box-shadow: ${({
  theme
}) => theme.shadowTop};
  z-index: 1049;
  color: ${({
  theme
}) => theme.colorWhite};
  text-align: center;
  @media (${({
  theme
}) => theme.underScreenXsmall}) {
    text-align: left;
  }
`;
const Inner = styled.div`
  padding: 20px 20px 26px;
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 10px 10px 13px;
  }
`;
const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 17px;
  color: ${({
  theme
}) => theme.colorGreyDark};
  &:hover, &:focus, &:active {
    color: ${({
  theme
}) => theme.colorGrey};
  }
`;
const Title = styled.div`
  ${titleMedium};
  color: ${({
  theme
}) => theme.colorWhite};
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    ${titleSmall};
    color: ${({
  theme
}) => theme.colorWhite};
  }
`;
const Content = styled.div`
  ${text};
  color: ${({
  theme
}) => theme.colorWhite};
  @media (${({
  theme
}) => theme.underScreenMedium}) {
    ${textSmall};
    color: ${({
  theme
}) => theme.colorWhite};
  }
`;
const Form = styled.form`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  @media (${({
  theme
}) => theme.underScreenMedium}) {
    margin-top: 5px;
  }
  @media (${({
  theme
}) => theme.underScreenXsmall}) {
    gap: 8px;
  }
`;
const EmailInput = styled.input`
  ${textLight};
  width: 350px;
  padding: 12px 20px;
  border: ${({
  theme
}) => theme.greyLine};
  border-radius: 0.25rem;
  &:focus {
    color: ${({
  theme
}) => theme.colorBlack};
  }
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 6px 12px;
  }
  @media (${({
  theme
}) => theme.underScreenXsmall}) {
    width: 70%;
    min-width: 218px;
  }
`;
const JoinButton = styled(Button)`
  margin: 0;
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 8px 20px;
  }
`;