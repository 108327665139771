import { useRouter } from 'next/router';
import { DropdownItem } from 'reactstrap';
import { setLangCookie } from 'src/utils/cookie';
import { updateLanguage } from 'src/modules/shared/context/actions';
import ButtonDropdown from 'src/components/buttons/buttonDropdown';
import i18n from 'src/utils/translate';
import { useDispatch, useSelector } from 'src/store/store';
import { Lang, Language, languages } from 'src/constants/language';
export default function LanguageChange() {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    lang = Lang.en
  } = useSelector(({
    context
  }) => context);
  const onChange = async (lang: Lang) => {
    setLangCookie(lang);
    await dispatch(updateLanguage(lang));
    i18n.changeLanguage(lang);
    router.reload();
  };
  return <ButtonDropdown color="languages" dataTestId="languages-button" text={`${i18n.t('Language')}: ${Language[lang]}`} data-sentry-element="ButtonDropdown" data-sentry-component="LanguageChange" data-sentry-source-file="languageChange.tsx">
      {languages.map(({
      label,
      value
    }) => <DropdownItem data-testid="language-selector" key={value} onClick={() => onChange(value)}>
          {label}
        </DropdownItem>)}
    </ButtonDropdown>;
}