import styled from 'styled-components';
import { titleMedium, textWideSpacing } from 'styles-js/mixins/typography';

export const ResultsContainer = styled.div`
  display: flex;
  column-gap: 80px;
  min-height: 300px;
  max-width: 1200px;
  margin: 0 auto 60px;
  padding: 15px;
  @media (${({ theme }) => theme.underScreenXlarge}) and (${({ theme }) => theme.overScreenSmall}) {
    margin-left: 20px;
  }  
  @media (${({ theme }) => theme.underScreenSmall}) {
    flex-direction: column;
    padding: 0;
  }

  .no-results-summary {
    @media (${({ theme }) => theme.underScreenSmall}) {
      padding: 0 15px;
    }
  }
  .search-tips.no-query {
    @media (${({ theme }) => theme.underScreenXlarge}) and (${({ theme }) => theme.overScreenSmall}) {
      margin-left: 0;
    }
  }
`;

export const ResultsContent = styled.div`
  width: 65%;
  @media (${({ theme }) => theme.underScreenMedium}) {
    width: 67%;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    width: 100%;
  }
`;

export const ResultsSidebar = styled.div`
  width: 35%;
  @media (${({ theme }) => theme.underScreenMedium}) {
    width: 33%;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const ResultsSeeMoreCTA = styled.div `
  text-align: center;
  padding: 10px;

  button {
    width: 100%;
  }
`;

export const SeeMoreResults = styled.div`
  text-align: center;
  padding: 10px 0;
  width: 100%;
  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 10px 20px;
  }

  button {
    width: 100%;
  }
`;

export const ResultsList = styled.div`
  border: ${({ theme }) => theme.greyLine};
  margin: 10px 0 30px;

  @media (${({ theme }) => theme.underScreenSmall}) {
    margin: 0 0 20px 0;
    border-right: 0;
    border-left: 0;
  }
`;

export const ResultsPost = styled.div`
  padding: 25px;
  border-bottom: ${({ theme }) => theme.greyLine};

  &:last-of-type {
    border-bottom: none;
  }

  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 20px;
  }
`;

export const ResultsPostTitle = styled.h3`
  ${titleMedium};
  margin: 0 0 10px;
  overflow-wrap: break-word;
  transition: all 0.5s ease;
  &:hover, &:focus, &:active {
    color: ${({ theme }) => theme.colorGreyDark};
  }
  @media (${({ theme }) => theme.overScreenSmall}) {
    font-size: 24px;
  }
`;

export const ResultsPostBody = styled.div`
  ${textWideSpacing};
  overflow-wrap: break-word;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 5px;
  max-height: calc(${({ theme }) => theme.fontLineHeightSWide} * 2);
  position: relative;
  display: block;
`;

export const ResultsPostFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: ${({ theme }) => theme.colorGreyDark};
`;

export const ResultsPostFooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
  gap: 8px;
`;

export const ResultsPostFooterLinkWrapper = styled.div`
  a {
    color: ${({ theme }) => theme.colorGreyDark};
    transition: all 0.5s ease;
    &:hover, &:focus, &:active {
      color: ${({ theme }) => theme.colorBlack};
    }
  }
`;

export const ResultsPostFooterReplies = styled.div`
  color: ${({ theme }) => theme.colorGreyDark};
  transition: all 0.5s ease;
  &:hover, &:focus, &:active {
    color: ${({ theme }) => theme.colorBlack};
  }
`;

export const EmptyResults = styled.div`
  @media (${({ theme }) => theme.overScreenSmall}) {
    margin: 30px 0;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 30px 15px;
    border-top: ${({ theme }) => theme.greyLine};
  }
`;
