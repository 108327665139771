import { useEffect, useState } from 'react';
import { throttle } from 'lodash';
import styled from 'styled-components';
import { FadeIn } from 'src/components/animate';
import i18n from 'src/utils/translate';
import Button from 'src/components/buttons/button';
export default function BackToTop() {
  const [showScroll, setShowScroll] = useState(false);
  const onScroll = throttle(() => setShowScroll(window.scrollY > 0), 300);
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);
  if (!showScroll) return null;
  return <FadeIn $isVisible={showScroll} data-sentry-element="FadeIn" data-sentry-component="BackToTop" data-sentry-source-file="backToTop.tsx">
      <StyledButton ariaLabel={i18n.t('Back to top')} icon="open-up" type="none" onClick={() => window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })} data-sentry-element="StyledButton" data-sentry-source-file="backToTop.tsx" />
    </FadeIn>;
}
const StyledButton = styled(Button)`
  position: fixed;
  right: 13px;
  bottom: ${({
  theme
}) => `calc(${theme.navHeightBottom} + 13px)`};
  z-index: 1048; // Below modals and site bars
  background: ${({
  theme
}) => theme.colorGreyLight};

  @media (${({
  theme
}) => theme.overScreenSmall}) {
    bottom: 13px;
  }

  @media print {
    display: none;
  }
`;