// @ts-strict-ignore
import { ReactNode } from 'react';
import styled from 'styled-components';
import { LinkButton } from 'styles-js/buttons';
import { text, titleSmall } from 'styles-js/mixins/typography';
type Props = {
  children: ReactNode;
  dataTestId?: string;
  isOpen: boolean;
};
export default function SitebarDropdown({
  children,
  dataTestId,
  isOpen
}: Props) {
  if (!isOpen) return null;
  return <Dropdown data-testid={dataTestId} data-sentry-element="Dropdown" data-sentry-component="SitebarDropdown" data-sentry-source-file="sitebarDropdown.tsx">
      {children}
    </Dropdown>;
}
export const Dropdown = styled.nav`
  position: absolute;
  display: block;
  width: 290px;
  max-height: ${({
  theme
}) => `calc(100vh - ${theme.navHeightTop} - 20px)`}; // sitebar height + 20px for extra padding
  overflow: auto;
  transition: opacity 0.1s ease-out, visibility 0s 0.1s;
  background: ${({
  theme
}) => theme.colorWhite};
  box-shadow: ${({
  theme
}) => theme.shadowDefault};
  border: ${({
  theme
}) => theme.greyLine};
  z-index: 2010;

  ul, li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a, button {
    display: block;
    &:hover, &:focus, &:active {
      background-color: ${({
  theme
}) => theme.colorBlueBackground};
    }
    ${text}
    color: ${({
  theme
}) => theme.colorBlack};
    padding: 7.5px 15px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    img {
      margin-right: 10px;
    }
  }
`;
export const SitebarDropdownLink = styled(LinkButton)`
  padding: 15px;
  display: block;
  width: 100%;
  text-align: left;
  &:hover, &:focus, &:active {
    background-color: ${({
  theme
}) => theme.colorBlueBackground};
  }
`;
export const Divider = styled.div`
  ${titleSmall};
  margin: 10px 15px;
  padding-bottom: 2px;
  border-bottom: ${({
  theme
}) => theme.greyLine};
`;