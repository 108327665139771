import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { capitalize } from 'underscore.string';
import Hub from 'src/components/siteBar/icons/hub';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { Section } from 'src/constants/footfall';
import MobileMoreMenu from 'src/components/siteBar/mobileMoreMenu';
import { WriteLink } from 'src/modules/community/shared/components/writeButton';
import { footfall } from 'src/components/siteBar/sitebarFootfall';
import { Counter, ImageContainer, LinkText, BottomSiteBar, StyledLink } from 'src/components/siteBar/sharedStyles';
import PaperPlane from 'src/components/siteBar/icons/paperPlane';
import Bell from 'src/components/siteBar/icons/bell';
import Menu from 'src/components/siteBar/icons/menu';
import Pen from 'src/components/siteBar/icons/pen';
export default function MobileSiteBarBottom() {
  const {
    pathname
  } = useRouter();
  const unreadMessagesCount = useSelector(({
    context
  }) => context?.unreadMessagesCount) ?? 0;
  const unreadNotificationsCount = useSelector(({
    context
  }) => context.notifications?.unreadNotificationsCount) ?? 0;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLAnchorElement>(null);
  const handleClickOutside = (event: MouseEvent): void => {
    if (!menuRef?.current?.contains((event.target as HTMLElement))) setIsMenuOpen(false);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []); // eslint-disable-line

  return <div ref={menuRef} data-sentry-component="MobileSiteBarBottom" data-sentry-source-file="mobileSiteBarBottom.tsx">
      <BottomSiteBar data-sentry-element="BottomSiteBar" data-sentry-source-file="mobileSiteBarBottom.tsx">
        <Link legacyBehavior passHref href="/my-hub" data-sentry-element="Link" data-sentry-source-file="mobileSiteBarBottom.tsx">
          <StyledLink $isActive={pathname.toLowerCase().includes('hub')} onClick={() => {
          footfall(Section.MyHub);
          setIsMenuOpen(false);
        }} data-sentry-element="StyledLink" data-sentry-source-file="mobileSiteBarBottom.tsx">
            <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="mobileSiteBarBottom.tsx">
              <Hub data-sentry-element="Hub" data-sentry-source-file="mobileSiteBarBottom.tsx" />
            </ImageContainer>
            <LinkText $isActive={pathname.toLowerCase().includes('hub')} data-sentry-element="LinkText" data-sentry-source-file="mobileSiteBarBottom.tsx">{capitalize(i18n.t('my hub'))}</LinkText>
          </StyledLink>
        </Link>

        <Link legacyBehavior passHref as="/messages" href="/messages" data-sentry-element="Link" data-sentry-source-file="mobileSiteBarBottom.tsx">
          <StyledLink $isActive={pathname.includes('messages')} onClick={() => {
          footfall(Section.Chat);
          setIsMenuOpen(false);
        }} data-sentry-element="StyledLink" data-sentry-source-file="mobileSiteBarBottom.tsx">
            <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="mobileSiteBarBottom.tsx">
              <PaperPlane data-sentry-element="PaperPlane" data-sentry-source-file="mobileSiteBarBottom.tsx" />
              {unreadMessagesCount > 0 && <Counter data-testid="chat-message-number">{unreadMessagesCount}</Counter>}
            </ImageContainer>
            <LinkText $isActive={pathname.includes('messages')} data-sentry-element="LinkText" data-sentry-source-file="mobileSiteBarBottom.tsx">{capitalize(i18n.t('chat'))}</LinkText>
          </StyledLink>
        </Link>

        <PostLink $isActive={pathname.includes('write')} onClick={() => footfall(Section.Post)} data-sentry-element="PostLink" data-sentry-source-file="mobileSiteBarBottom.tsx">
          <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="mobileSiteBarBottom.tsx">
            <Pen data-sentry-element="Pen" data-sentry-source-file="mobileSiteBarBottom.tsx" />
          </ImageContainer>
          <LinkText $isActive={pathname.includes('write')} data-sentry-element="LinkText" data-sentry-source-file="mobileSiteBarBottom.tsx">{capitalize(i18n.t('post'))}</LinkText>
        </PostLink>

        <Link legacyBehavior passHref href="/notifications" data-sentry-element="Link" data-sentry-source-file="mobileSiteBarBottom.tsx">
          <StyledLink $isActive={pathname.includes('notifications')} onClick={() => {
          footfall(Section.Alerts);
          setIsMenuOpen(false);
        }} data-sentry-element="StyledLink" data-sentry-source-file="mobileSiteBarBottom.tsx">
            <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="mobileSiteBarBottom.tsx">
              <Bell data-sentry-element="Bell" data-sentry-source-file="mobileSiteBarBottom.tsx" />
              {unreadNotificationsCount > 0 && <Counter>{unreadNotificationsCount}</Counter>}
            </ImageContainer>
            <LinkText $isActive={pathname.includes('notifications')} data-sentry-element="LinkText" data-sentry-source-file="mobileSiteBarBottom.tsx">{capitalize(i18n.t('alerts'))}</LinkText>
          </StyledLink>
        </Link>

        <StyledLink $isActive={isMenuOpen} href="#" ref={menuButtonRef} onClick={e => {
        e.preventDefault();
        setIsMenuOpen(!isMenuOpen);
        menuButtonRef?.current?.blur();
        footfall(Section.More);
      }} data-sentry-element="StyledLink" data-sentry-source-file="mobileSiteBarBottom.tsx">
          <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="mobileSiteBarBottom.tsx">
            <Menu data-sentry-element="Menu" data-sentry-source-file="mobileSiteBarBottom.tsx" />
          </ImageContainer>
          <LinkText $isActive={isMenuOpen} data-sentry-element="LinkText" data-sentry-source-file="mobileSiteBarBottom.tsx">{capitalize(i18n.t('menu'))}</LinkText>
        </StyledLink>
      </BottomSiteBar>

      <MobileMoreMenu isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} data-sentry-element="MobileMoreMenu" data-sentry-source-file="mobileSiteBarBottom.tsx" />
    </div>;
}
const PostLink = styled(WriteLink)<{
  $isActive: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  color: ${({
  theme
}) => theme.colorBlueDark};
  ${LinkText} {
    color: ${({
  theme
}) => theme.colorBlueDark};
  }
  &:hover, &:focus, &:active {
    :before {
      background-color: ${({
  theme
}) => theme.colorBlueLight};
    }
  }
  ${({
  $isActive
}) => $isActive && css`
    color: ${({
  theme
}) => theme.colorGreyDark};
    ${LinkText} {
      color: ${({
  theme
}) => theme.colorGreyDark};
    }
    :before {
      background-color: ${({
  theme
}) => theme.colorBlueBackground} !important;
    }
  `}
  :before {
    position: absolute;
    top: -10px;
    content: '';
    display: block;
    width: calc(${({
  theme
}) => theme.navHeightTop} + 20px);
    height: calc(${({
  theme
}) => theme.navHeightTop} + 20px);
    background-color: ${({
  theme
}) => theme.colorBlueLighter};
    border-radius: 50%;
  }
  div, span {
    z-index: 1;
  }
`;